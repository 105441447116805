var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"VOC row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"verti-timeline left-timeline"},_vm._l((_vm.vocData),function(item,index){return _c('div',{key:index,class:`timeline-item`},[_c('div',{staticClass:"timeline-block"},[_c('div',{staticClass:"timeline-box card"},[_c('div',{staticClass:"VocCardBody card-body"},[_c('div',{staticClass:"headerArea"},[_c('div',{staticClass:"nameDateArea"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.c_writer === ''),expression:"item.c_writer === ''"}],staticStyle:{"font-size":"0.7em"}},[_vm._v(_vm._s("(미인증고객)"))]),_c('p',{staticClass:"nameDate card-title mt-0"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.c_writer != ''),expression:"item.c_writer != ''"}]},[_vm._v(_vm._s(item.c_writer + " (" + item.c_phone + ")"))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.c_writer === ''),expression:"item.c_writer === ''"}]},[_vm._v(_vm._s("회원번호 " + item.c_device_srl))]),_c('span',{staticStyle:{"font-size":"0.8em"}},[_vm._v(_vm._s(item.c_date))])])]),_c('div',[_c('p',{staticClass:"card-text",staticStyle:{"position":"relative"}},[_vm._v("   "),_c('span',{staticStyle:{"position":"absolute","left":"1px"}},[_vm._v(" "+_vm._s(item.c_comment)+" ")])])])]),_c('div',{staticClass:"w-100",staticStyle:{"padding-top":"14px"}},[_c('b-card',{staticStyle:{"margin-bottom":"0","padding":"0"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        'm_voc_srl' in item.answer && item.answer.m_date != ''
                      ),expression:"\n                        'm_voc_srl' in item.answer && item.answer.m_date != ''\n                      "}],staticStyle:{"margin-bottom":"4px"}},[_c('p',{staticClass:"card-title mt-0",staticStyle:{"position":"relative","padding":"0"}},[_vm._v("   "),_c('span',{staticStyle:{"position":"absolute","right":"0","bottom":"0","font-size":"0.8em"}},[_vm._v(_vm._s(item.answer.m_date))])]),_c('b-card-text',[_vm._v(_vm._s(item.answer.m_comment))])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        'm_voc_srl' in item.answer != true ||
                        item.answer.m_date === ''
                      ),expression:"\n                        'm_voc_srl' in item.answer != true ||\n                        item.answer.m_date === ''\n                      "}]},[_c('div',{staticStyle:{"margin-bottom":"4px"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.answer.m_comment),expression:"item.answer.m_comment"}],staticClass:"form-control",attrs:{"placeholder":"댓글을 입력해주세요.","id":"gen-info-description-input","rows":"3"},domProps:{"value":(item.answer.m_comment)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item.answer, "m_comment", $event.target.value)}}})])]),_c('a',{directives:[{name:"show",rawName:"v-show",value:('m_voc_srl' in item.answer === false),expression:"'m_voc_srl' in item.answer === false"}],staticClass:"btn btn-primary w-100",on:{"click":function($event){return _vm.submitReply(item.c_voc_srl, index)}}},[_vm._v("전송")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(
                        'm_voc_srl' in item.answer === true &&
                        item.answer.m_modify === 'Y'
                      ),expression:"\n                        'm_voc_srl' in item.answer === true &&\n                        item.answer.m_modify === 'Y'\n                      "}],staticClass:"btn btn-primary w-100",staticStyle:{"background":"darkorange","border-color":"#eeeeee"},on:{"click":function($event){return _vm.modifyProcess(
                          item.idx,
                          item.answer.m_date,
                          index,
                          item.answer.m_voc_srl
                        )}}},[_vm._v("수정")])])],1)])])])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }